import { httpRequest } from '@sales-i/utils';

import {
  CHECK_PERMISSIONS,
  CHECK_FEATURE_FLAGS,
} from '@/shared/store/actionType';
import {
  customers,
  intelligence_products,
  intelligence_crm_reports,
  intelligence_insights,
  intelligence_enquiries,
} from '@/shared/store/data/policies';
import { checkAccessAgainstArray } from '@/shared/store/utils/checkAccessAgainstArray';

export const checkPermissionsGetters = {
  hasAccessToPerformanceInsights: state => {
    return checkAccessAgainstArray(state.permissionsGranted, [
      intelligence_crm_reports,
    ]);
  },
  hasAccessToProductInsights: state => {
    return checkAccessAgainstArray(state.permissionsGranted, [
      intelligence_products
    ]);
  },
  hasAccessToCustomerInsights: state => {
    return checkAccessAgainstArray(state.permissionsGranted, [
      intelligence_insights
    ]);
  },
  hasAccessToSnapshots: state => {
    // As long as the user has access to all of these reports, hasAccessToSnapshots will return true
    return checkAccessAgainstArray(state.permissionsGranted, [
      intelligence_insights,
      intelligence_products,
      intelligence_enquiries,
    ]);
  },
  hasAccessToAllInsights: state => {
    return checkAccessAgainstArray(state.permissionsGranted, [
      intelligence_crm_reports,
      intelligence_products,
      customers,
      intelligence_insights,
      intelligence_enquiries,
    ]);
  },
};



// mutations
export const checkPermissionsMutations = {
  [CHECK_PERMISSIONS]: (state, permissionsGranted) => {
    state.permissionsGranted = permissionsGranted;

    /* Disables ALL insights
    -------------------------------------
    state.permissionsGranted.intelligence_insights = {
      read: false,
    };
    ------------------------------------*/


    /*======================================
    PERFORMANCE INSIGHTS
    ======================================*/

    /* Disables ALL performance insights
    -------------------------------------
    state.permissionsGranted.intelligence_crm_reports = {
      read: false,
    };
    ------------------------------------*/



    /*======================================
    PRODUCT INSIGHTS
    ======================================*/

    // state.permissionsGranted.reports_productgroupsale = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_productsale = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_producttrend = {
    //   read: false,
    // };



    /*======================================
    CUSTOMER INSIGHTS
    ======================================*/
    // Global blocking of access to ALL customer areas, including reports
    // state.permissionsGranted.crm_customers = {
    //   read: false,
    // };

    // state.permissionsGranted.reports_customersale = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_customersdecreasingspend = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_customersdormantytd = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_customersdowninrevenue = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_customersnospendthisperiod = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_customersnotbuyingtopproducts = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_customerszerospend = {
    //   read: false,
    // };

    
    /*======================================
    SNAPSHOT REPORT DROPDOWN
    ======================================*/
    // state.permissionsGranted.reports_snapshots = {
    //   read: false,
    // };

    // state.permissionsGranted.reports_snapshots = {
    //   read: false,
    // };

    // state.permissionsGranted.reports_accountsanalysis = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_frequentpurchases = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_latestpurchases = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_productgrouppurchaseanalysis = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_productgroupsale = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_productsale = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_transactionsanalysis = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_trends = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_salesvsgaps = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_varianceplus = {
    //   read: false,
    // };
    


    /*======================================
    ENQUIRIES INSIGHTS
    ======================================*/
    /* Disables ALL enquiry reports
    -------------------------------------
    state.permissionsGranted.intelligence_enquiries = {
      read: false,
    };
    ------------------------------------*/

    // state.permissionsGranted.reports_fullpicture = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_matrix = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_salesvsgaps = {
    //   read: false,
    // };
    // state.permissionsGranted.reports_varianceplus = {
    //   read: false,
    // };


    /*======================================
    INTERACTIONS
    ======================================*/
    // state.permissionsGranted.crm_interactions = {
    //   read: false,
    // };

    /*======================================
    OPPORTUNITIES
    ======================================*/
    // state.permissionsGranted.crm_opportunities = {
    //   read: false,
    // };

  },
  [CHECK_FEATURE_FLAGS]: (state, featureFlags) => {
    state.featureFlags = featureFlags;
  },
};

// actions
export const checkPermissionsActions = {
  // function used for menu building, accepts array of permissions, returns if accessGranted to those permissions
  [CHECK_PERMISSIONS]: async ({ commit }) => {
    try {
      let facets = await httpRequest('get', 'subjects/facets', {}, {
        version: 2,
        area: 'iam',
      });

      let permissionsGranted = {};
      let arr = [];

      commit(CHECK_FEATURE_FLAGS, facets.features);
    
      facets.permissions.forEach(permission => {
        arr.push(permission.resource);

        permissionsGranted[permission.resource] = {
          create: permission.actions.includes('C'),
          read: permission.actions.includes('R'),
          update: permission.actions.includes('U'),
          delete: permission.actions.includes('D'),
        };
      });

      commit(CHECK_PERMISSIONS, permissionsGranted);
    } catch (e) {
      console.error(e);
    }
  },
};