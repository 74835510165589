import {
  GET_SUMMARY,
  GET_SUMMARY_LOADING,
  GET_SUMMARY_ERROR,
} from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

export const getSummaryGetters = {
  openCount: state => {
    return state.summary.data.open || 0;
  },
  missedCount: state => {
    return state.summary.data.missed || 0;
  }
};

// mutations
export const getSummaryMutations = {
  [GET_SUMMARY]: (state, data) => {
    state.summary.data = data;
    state.summary.loaded = true;
    state.summary.loading = false;
    state.summary.error = undefined;
  },

  [GET_SUMMARY_LOADING]: (state) => {
    state.summary.loaded = false;
    state.summary.loading = true;
    state.summary.error = undefined;
  },
  [GET_SUMMARY_ERROR]: (state, error) => {
    state.summary.loaded = false;
    state.summary.loading = false;
    state.summary.error = error;
  },
};

// actions
/**
 * parameters:
 *  allocated_to
 *  customer
 *  prospect
 *  offset
 *  limit
 *  sort
 */
export const getSummaryActions = {
  [GET_SUMMARY]: async ({ commit, }, parameters) => {
    try {
      const params = querystring.stringify(parameters);
      
      commit(GET_SUMMARY_LOADING);

      let data = await httpRequest('get', `interactions/summary?${params}`, {}, {version: '2',});

      commit(GET_SUMMARY, data);
      return true;
    } catch (error) {
      commit(GET_SUMMARY_ERROR, error);
      return false;
    }
  },
};
