import { getSetVisibleMutations, getSetVisibleActions } from './getSetVisible';
import { getAllMutations, getAllActions } from './getAll';
import { deleteByIdMutations, deleteByIdActions } from './deleteById';

// Set visible as a boolean value
const state = () => ({
  visible: false,
  data: [],
  loaded: false,
  loading: false,
});

const getters = {};

const mutations = {
  ...getSetVisibleMutations,
  ...getAllMutations,
  ...deleteByIdMutations
};

const actions = {
  ...getSetVisibleActions,
  ...getAllActions,
  ...deleteByIdActions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
