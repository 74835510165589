<template>
  <!-- eslint-disable vue/no-v-html -->
  <CRMCard
    :key="`${item.id}-${mailOpen}`"
    :on-click="composing ? null : itemClicked"
    :title="highlight(item.header, item.query)"
    :chips="getChips(item)"
    :links="getCardLinks(item)"
    :show-footer="mailOpen"
    @open="itemClicked"
    @edit="edit"
    @map-location="handleMapLocation"
    @delete="deleteItem"
  >
    <template #info>
      <span v-html="highlight(item.subHeader, item.query)" />
      <div v-if="type === 'opportunity'">
        {{
          t(`DD: ${dates.format(item.decision_date)}`, 'dd_date', {
            interpolations: {
              date: dates.format(item.decision_date),
            },
          })
        }}
      </div>

      <AddressField
        v-if="type === 'customer' && item.addresses"
        :addresses="item.addresses"
        :query="item.query"
      />
    </template>
    <template
      v-if="type === 'contact' && item.email && authData.status === 'VALID'"
      #icons
    >
      <template v-if="!loadingMail">
        <CustomButton
          icon-name="email"
          icon-color="var(--colour-utility-action)"
          icon-only
          round
          small
          purpose="neutral"
          :icon-width="20"
          :icon-height="20"
          @on-click="handleViewMail($event, item.id)"
        />
      </template>
      <template v-else>
        <BufferImage
          :size="20"
          color="var(--colour-utility-black)"
        />
      </template>
    </template>
    <template #tags>
      <Tag
        :id="item.id"
        :entity="type"
      />
    </template>
    <template
      v-if="mailOpen"
      #footer
    >
      <MiniMail
        :name="item.header"
        :email-address="item.email"
        :items="loadedContent"
        :mode="mailMode"
        :loading="loadingMail"
        :show-view-more="viewMorePossible"
        @composing="setComposing"
        @view-more="loadMoreMail"
      />
    </template>
  </CRMCard>
</template>

<script setup>
import { ref, computed, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { CRMCard, CustomButton, BufferImage } from '@sales-i/dsv3';
import { address, currency, dates, pluralise, t, mailState, getMail } from '@sales-i/utils';
import AddressField from '@/crm/components/Common/AddressField.vue';
import Tag from '@/shared/components/Tags/Tag.vue';
import { highlight } from '@/shared/utils/highlight';
import { navigateToUrl } from 'single-spa';
import { baseUrl, contactsSection, opportunitiesArea, prospectsArea } from '@/crm/router/urlBits';
import MiniMail from '@/shared/components/Mail/MiniMail.vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['itemClicked', 'deleteItem', 'searchMore']);

const store = useStore();

const authData = ref({});

const rxm = mailState.subscribe(newMailState => {
  authData.value = newMailState.details;
});

const loadedContent = ref([]);
const composing = ref(false);
const mailMode = ref('view');
const loadingMail = ref(false);
const mailOpen = ref(false);
const viewMorePossible = ref(true);
const limit = ref(3); // this is never changed, just makes sense to scope it here
const offset = ref(0);

const journey = computed(() => store.state.journey.data);
const currencyName = computed(() => store.state.userDetails.currencyName);

const itemClicked = () => {
  emit('itemClicked', props.item);
};
const edit = (link) => {
  let { item } = link;
  if (props.type === 'contact') {
    navigateToUrl(
      `${baseUrl}/${pluralise.string(item.relationship?.record_type)}/${item.relationship?.id}/${contactsSection}/${item.id}/edit`
    );
  } else if (props.type === 'prospect') {
    navigateToUrl(`${baseUrl}/${prospectsArea}/${item.id}/edit`);
  } else if (props.type === 'opportunity') {
    navigateToUrl(`${baseUrl}/${opportunitiesArea}/${item.id}/edit`);
  }
};
const deleteItem = (link) => emit('deleteItem', link.item);
const handleMapLocation = ({ item }) => address.showOnMap(item.address);
const getChips = (item) => {
  if (props.type === 'opportunity') {
    return [
      {
        value: item.value,
        prefix: currency.symbol(currencyName),
        abbr: true,
        green: 150000,
        amber: 75000,
      },
      {
        value: item.probability,
        suffix: '%',
        green: 67,
        amber: 33,
      },
    ];
  }
  return [];
};
const getCardLinks = (item) => {
  const openObj = {
    text: t('Open'),
    item: item,
    emit: 'open',
  };
  const editObj = {
    text: t('Edit'),
    item: item,
    emit: 'edit',
  };
  const deleteObj = {
    text: t('Delete'),
    item: item,
    emit: 'delete',
  };
  if (journey.value.entity && journey.value.action) {
    return [
      {
        text: t('Select'),
        item: item,
        emit: 'open',
      },
    ];
  }
  if (props.type === 'customer') {
    return [
      address.isValid(item.address)
        ? {
          text: t('Map Location'),
          item: item,
          emit: 'map-location',
        }
        : {},
      openObj,
    ];
  }
  if (props.type === 'contact') {
    return [
      openObj,
      editObj,
      {
        text: item.mobile_phone ? t('Call') : '',
        type: 'a',
        href: `tel:${item.mobile_phone}`,
      },
      {
        text: item.email ? t('Email') : '',
        type: 'a',
        href: `mailto:${item.email}`,
      },
    ];
  }
  if (props.type === 'prospect') {
    return [openObj, editObj];
  }
  if (props.type === 'opportunity') {
    return item.status === 'open' ? [openObj, editObj, deleteObj] : [openObj, deleteObj];
  }
  if (props.type === 'interaction') {
    return [openObj, deleteObj];
  }
  return [];
};
const handleViewMail = async(e) => {
  e.stopPropagation();
  if (mailOpen.value) {
    mailOpen.value = false;
    loadedContent.value = [];
    offset.value = 0;
    viewMorePossible.value = true;
  } else {
    await loadMail();
    mailOpen.value = true;
  }
};

const loadMail = async() => {
  loadingMail.value = true;
  let results = await getMail({
    limit: limit.value,
    // offset: offset.value, need to replace with v3 equivalent, non-working now
    any_email: props.item.email,
  }) ?? [];
  if (results.length < limit.value) {
    viewMorePossible.value = false;
  }
  loadedContent.value = [
    ...results,
  ];
  loadingMail.value = false;
  return loadedContent.value;
};

const loadMoreMail = () => {
  offset.value += 3;
  loadMail();
};
const setComposing = (isComposing) => {
  composing.value = isComposing;
  mailMode.value = isComposing ? 'message' : 'view';
};

onUnmounted(() => {
  rxm.unsubscribe();
});
</script>

<style lang="scss" scoped>
.search-bar-result {
  padding: var(--spacing-2);
  overflow: auto;
  min-height: calc(100% - 96px);
  .list-card.list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  }
}
.more-btn {
  display: block;
  margin: var(--spacing-1) auto;
}

// move to separate component I guess?
.recent-emails-heading {
  font-weight: var(--font-weight-semibold);
  text-align: left;
}
.mail-item {
  display: flex;
  padding: var(--spacing-1);
  padding-left: 0;
  gap: var(--spacing-1);
}

.initials-tag {
  font-size: var(--font-size-small);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: lightblue;
  color: darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subject-tag {
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
  font-style: italic;
}
</style>
