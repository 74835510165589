import { createRouter, createWebHistory } from 'vue-router';
import AppBar from '@/shared/components/AppBar.vue';
import { setPlatform, } from '@/shared/utils/platform';

const routes = [
  {
    path: '/desktop',
    component: AppBar,
    beforeEnter: (to, from, next) => {
      setPlatform('desktop');
      next();
    },
  },
  {
    path: '/mobile',
    component: AppBar,
    beforeEnter: (to, from, next) => {
      setPlatform('mobile');
      next();
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: AppBar, // not actually rendered, prevents router warnings
  },
];

const router = createRouter({
  history: createWebHistory(), // THIS NEEDS TO BE HASH UNTIL HOSTING CHANGE BECAUSE WE CAN'T CONFIG (HOSTING SIDE) PROPERLY CURRENTLY
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 64, // Takes into account the fixed header
      };
    } else {
      return { top: 0 };
    }
  },
});

export default router;
