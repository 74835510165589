import {
  GET_FIELDS,
  CLEAR_FIELDS
} from '@/shared/store/actionType';
import store from '@/shared/store';
import { prospectData } from '@/crm/store/data/prospect';

// mutations
export const fieldsMutations = {
  [GET_FIELDS]: async (state, { data, }) => {
    let dataTemp = data;
    let prospect = store.state.crm.prospects.selected.data;
    let contact = store.state.crm.contacts.selected.data;
    let prospectAddress = prospect.addresses && prospect.addresses.length > 0 ? prospect.addresses[0] : false;
    let contactAddress = contact.addresses && contact.addresses.length > 0 ? contact.addresses[0] : false;

    dataTemp = data.map(e => {
      let section = e.section.toLowerCase();
      const fields = e.fields.map(field => {
        if (section === 'customer') {
          if (prospect[field.id]) {
            field.value = prospect[field.id];
          }
          if (field.id === 'customer' && prospect.name) {
            field.value = prospect.name;
          }
          if (prospectAddress && prospectAddress[field.id]) {
            field.value = prospectAddress[field.id];
          }
          field.id = `${section}_${field.id}`;
        }

        if (section === 'contact') {
          if (contact[field.id]) {
            field.value = contact[field.id];
          }
          if (contactAddress && contactAddress[field.id]) {
            field.value = contactAddress[field.id];
          }
          field.id = `${section}_${field.id}`;
        }

        if (section === 'details') {
          if (prospect[field.id]) {
            field.value = prospect[field.id];
          }
          field.id = `${section}_${field.id}`;
        }

        return field;
      });
      e.fields = fields;
      return e;
    });
    state.fields = dataTemp;
  },
  [CLEAR_FIELDS]: (state) => {
    state.fields = [];
    state.selected.loading = false;
    state.selected.loaded = true;
  },
};

// actions
export const fieldsActions = {
  [GET_FIELDS]: ({ commit, }) => {
    const data = JSON.parse(JSON.stringify(prospectData));
    commit(GET_FIELDS, {
      data,
    });
  },
  [CLEAR_FIELDS]: async ({ commit, }) => {
    commit(CLEAR_FIELDS);
  },
};
