<template>
  <div class="search-bar-details">
    <div class="row">
      <div class="col col-11">
        <h4>
          {{
            t(
              `Search ${type}`,
              'search_type',
              { interpolations: { type } }
            )
          }}
          <span v-if="type !== 'Customers' && !isGlobal"> at {{ customerName }}</span>
        </h4>
        <small v-if="type === 'Customers'">
          {{
            t(
              'Accepted terms: Customer Name, Account Number, Town, Postcode',
              'accepted_terms_termlist',
              {
                interpolations: { termlist: t('Customer Name, Account Number, Town, Postcode') },
              }
            )
          }}
        </small>
        <small v-if="type === 'Contacts'">
          {{
            t(
              'Accepted terms: Contact Name',
              'accepted_terms_termlist',
              {
                interpolations: { termlist: t('Contact Name') },
              }
            )
          }}
        </small>
        <small v-if="type === 'Prospects'">
          {{
            t(
              'Accepted terms: Prospect Name, Town, Postcode',
              'accepted_terms_termlist',
              {
                interpolations: { termlist: t('Prospect Name, Town, Postcode') },
              }
            )
          }}
        </small>
        <small v-if="['Opportunities', 'Interactions'].includes(type)">
          {{
            t(
              'Accepted terms: Customer Name, Prospect Name or Assignee',
              'accepted_terms_termlist',
              {
                interpolations: { termlist: t('Customer Name, Prospect Name or Assignee') },
              }
            )
          }}
        </small>
        <small v-if="(!query || query.length < 3) && ['Contacts'].includes(type)">
          {{ t('Search Criteria: 3 Letters') }}
        </small>
        <div>
          <small
            v-if="!isGlobal && type !== 'Customers'"
            class="link clear-both"
            role="button"
            tabindex="0"
            @click="searchAll"
            @keydown.enter="searchAll"
          >
            {{
              t(`Search all ${type}`, 'search_all_type', {
                interpolations: { type },
              })
            }}
          </small>
        </div>
      </div>
      <div class="col col-1">
        <button @click="close">
          <IconBase
            icon-name="close-line"
            icon-color="var(--colour-utility-black)"
            :height="24"
            :width="24"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { t } from '@sales-i/utils';
import { IconBase } from '@sales-i/dsv3';

export default {
  name: 'SearchBarResults',
  components: {
    IconBase,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    isGlobal: {
      type: Boolean,
      default: false,
    },
    customerName: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'searchAll'],
  methods: {
    t,
    close() {
      this.$emit('close');
    },
    searchAll() {
      this.$emit('searchAll');
    },
    searchLocal() {
      this.$emit('searchAll');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-bar-details {
  position: sticky;
  padding: var(--spacing-2);
  background-color: var(--colour-utility-white);
  top: 0;
  background: white;
  z-index: 1;
  small {
    display: block;
    margin-top: calc(var(--spacing-1) / 2);
  }
}
</style>
