<template>
  <CustomButton
    icon
    @click.stop="customerModeClicked"
  >
    <IconBase
      aria-pressed="isCustomerModeEnabled"
      aria-label="Customer mode"
      :class="`${mq.current}`"
      icon-color="iconColor"
      :icon-name="isCustomerModeEnabled ? 'profit-hide' : 'profit-show'"
      purpose="transparent"
      :width="iconWidth"
      :height="iconHeight"
    />
  </CustomButton>

  <!-- Customer mode 'toast' -->
  <aside
    v-if="isToastVisible"
    class="toast"
    :class="{ OFF: isCustomerModeEnabled, ON: !isCustomerModeEnabled }"
  >
    <div v-if="!isCustomerModeEnabled">
      <h3>{{ t('Profit visible') }}</h3>
      <p>
        <i>{{ t('Customer mode is disabled. All profit data will be visible') }}.</i>
      </p>
    </div>

    <div v-if="isCustomerModeEnabled">
      <h3>{{ t('Profit hidden') }}</h3>
      <p>
        <i>{{ t('Customer mode is enabled. All profit data will be hidden') }}.</i>
      </p>
    </div>
  </aside>
</template>
<script>
import { IconBase, CustomButton } from '@sales-i/dsv3';
import { mapActions, mapState } from 'vuex';
import { TOGGLE_CUSTOMER_MODE } from '@/admin/store/actionType';
import { t, toggleCustomerMode as rxtoggleCustomerMode } from '@sales-i/utils';

export default {
  name: 'AppBar',
  components: {
    IconBase,
    CustomButton
  },
  inject: ['mq'],
  data() {
    return {
      isToastVisible: false,
      timeout: null,
      iconWidth: 32,
      iconHeight: 32,
    };
  },
  computed: {
    ...mapState({
      isCustomerModeEnabled: state => state.admin.intelligence.isCustomerModeEnabled,
    }),
  },
  mounted() {
    if (localStorage.getItem('isCustomerModeEnabled')) {
      const isModeEnabled = localStorage.getItem('isCustomerModeEnabled') === 'true';
      rxtoggleCustomerMode(isModeEnabled);
    }
  },
  methods: {
    t,
    ...mapActions({
      toggleCustomerMode: `admin/intelligence/${TOGGLE_CUSTOMER_MODE}`,
    }),
    customerModeClicked() {
      // Toggle customer mode icon (toggle functionality in the store)
      this.toggleCustomerMode();
      rxtoggleCustomerMode(this.isCustomerModeEnabled);

      // Toggle 'toast' message.
      this.isToastVisible = !this.isToastVisible;

      if (this.isToastVisible) {
        clearTimeout(this.timeout);
      } else {
        this.isToastVisible = true;
      }

      this.timeout = setTimeout(() => {
        this.isToastVisible = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: flex-end;
}

.icon-button {
  border-radius: 50%;

  &:hover {
    background-color: var(--colour-panel-g-4);
  }

  &:active {
    background-color: var(--colour-panel-g-24);
  }
}

.toast {
  padding: var(--spacing-3) var(--spacing-2);
  margin-left: var(--spacing-2); // Purely for small screens so it's not cut-off
  max-width: 360px;
  position: absolute;
  top: calc(var(--spacing-8) + var(--spacing-1));
  right: var(--spacing-2);

  h3 {
    font-weight: var(--font-weight-semibold);
    line-height: 1.2;
  }

  p {
    font-weight: var(--font-weight-medium);
  }
}

.toast.ON {
  background-color: var(--colour-data-de-york-background);
}
.toast.OFF {
  background-color: var(--colour-data-mandy-background);
}

.svg-container {
  width: 32px;
  height: 32px;
}
</style>
