import { fieldsMutations, fieldsActions } from './fields';
import { getByIdMutations, getByIdActions } from './getById';

// initial state
const state = () => ({
  selected: {
    data: {},
    errors: [],
    loaded: false,
    loading: false,
    updated: false,
  },
  default: {
    data: {},
    loaded: false,
    loading: false,
  },
  fields: []
});

// getters
const getters = {
};

// mutations
const mutations = {
  ...fieldsMutations,
  ...getByIdMutations,
};

// actions
const actions = {
  ...fieldsActions,
  ...getByIdActions,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
