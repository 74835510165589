import {
  SET_VISIBLE,
  CLEAR_VISIBLE,
} from '@/shared/store/actionType';

export const getSetVisibleMutations = {
  [SET_VISIBLE]: (state) => {
    state.visible = true;
  },
  [CLEAR_VISIBLE]: (state) => {
    state.visible = false;
  },
};

export const getSetVisibleActions = {
  [SET_VISIBLE] ({ commit }) {
    commit(SET_VISIBLE);
  },
  [CLEAR_VISIBLE] ({ commit }) {
    commit(CLEAR_VISIBLE);
  },
};