export const disablePageScroll = () => {
  document.body.style.setProperty('overflow', 'hidden', 'important');
  document.body.style.height = '100vh';
};


export const enablePageScroll = () => {
  document.body.style.overflow = null;
  document.body.style.height = null;
};
