<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <nav
    v-click-outside="handleClickOutside"
    class="navigation"
  >
    <ul class="nav-primary">
      <li
        v-for="(item, index) in computedItems"
        :key="item.title"
        :class="{ active: item.active, 'solo-item': !item.sections }"
        @click="navClicked(index, item)"
        @mouseenter="handleMouseEnter(index)"
        @mouseleave="handleMouseLeave"
      >
        {{ item.title }}
        <template v-if="item.sections">
          <IconBase
            :class="selectedAreaIndex === index ? 'chevron-up' : 'chevron-down'"
            icon-name="chevron-down"
            :height="32"
            :width="32"
            :icon-color="hoverAreaIndex === index || item.active ? 'var(--colour-data-puerto-rico)' : '#7360A4'"
          />

          <span
            v-show="selectedAreaIndex === index"
            class="pin"
          />

          <div
            v-if="selectedAreaIndex === index"
            :class="{ 'nav-secondary': true, single: item.sections.length === 1 }"
          >
            <div
              v-for="section in item.sections"
              :key="section.title"
              :class="{ settings: section.settings }"
            >
              <h3 v-if="section.title">
                {{ section.title }}
              </h3>
              <ul class="section">
                <li
                  v-for="(link, linkIndex) in section.items"
                  :key="link.title"
                  class="nav-secondary__item"
                  :class="{ active: $route.fullPath.includes(link.path) }"
                  @click.prevent="linkClicked($event, link, { areaIndex: index, linkIndex: linkIndex })"
                >
                  <a
                    v-if="isDemo && link.demoPath"
                    :href="link.path"
                    :title="link.demoTitle"
                  >
                    {{ link.demoTitle || link.title }}
                  </a>

                  <a
                    v-else
                    :href="link.path"
                    :title="link.title"
                  >
                    {{ link.title }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ClickOutside } from 'vue-click-outside-of';
import { IconBase } from '@sales-i/dsv3';
import { SET_VISIBLE } from '@/shared/store/actionType';
import { mapState, mapActions, mapGetters } from 'vuex';
import { REPORT_AREA_PRODUCT } from '@/intelligence/store/data/areas';

import {
  baseUrl as intelligenceBaseUrl,
  enquiryScope,
  insightsScope,
  alertsScope
} from '@/intelligence/router/urlBits';
import {
  baseUrl as crmBaseUrl,
} from '@/crm/router/urlBits';
import { navigateToUrl } from 'single-spa';

export default {
  name: 'Navigation',
  directives: { ClickOutside },
  components: {
    IconBase,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedPath: [],
      selectedAreaIndex: undefined,
      selectedLinkIndex: undefined,
      hoverAreaIndex: undefined,
      activeIndex: undefined,
    };
  },
  computed: {
    ...mapState({
      platform: state => state.platform,
    }),
    ...mapGetters({
      isDemo: 'system/isDemo',
    }),
    computedItems() {
      const navItems = {
        0: {
          path: `${intelligenceBaseUrl}/${enquiryScope}`,
        },
        1: {
          path: `${intelligenceBaseUrl}/${insightsScope}`,
        },
        2: {
          path: `${intelligenceBaseUrl}/${alertsScope}`,
        },
        3: {
          path: crmBaseUrl,
        },
      };
      const route = this.$route;
      return this.items.map((navItem, i) => ({
        ...navItem,
        active: route.path.includes(navItems[i].path),
      }));
    },
    demoLink() {
      if (this.isDemo) {
        return this.demoTitle;
      } else {
        return this.title;
      }
    },
  },
  watch: {
    $route() {
      this.selectedPath = [];
    },
  },
  methods: {
    handleClickOutside() {
      this.selectedAreaIndex = undefined;
    },
    ...mapActions({
      setVisible: `reportsModal/${SET_VISIBLE}`,
    }),
    navClicked(index, item) {
      this.selectedAreaIndex = this.selectedAreaIndex === index ? undefined : index;
      if (!item.sections && item.path) {
        if (this.$route.path !== item.path) {
          navigateToUrl(item.path);
        }
      }
    },
    linkClicked(event, link, data) {
      event.stopPropagation();

      this.selectedAreaIndex = undefined;
      this.hoverAreaIndex = undefined;
      this.selectedLinkIndex = data.linkIndex;

      let entity = ['Template reports'];

      // Make the link.title lowercase and remove the 's' at the end.
      // Use this as the filter name for the search bar
      if (entity.includes(link.title)) {
        if (link.title === 'Template reports') {
          this.setVisible(REPORT_AREA_PRODUCT);
        }
      } else if (this.$route.path !== link.path) {
        navigateToUrl(link.path);
      }
    },
    handleMouseEnter(index) {
      this.activeIndex = index;
      this.hoverAreaIndex = this.hoverAreaIndex === index ? undefined : index;
    },
    handleMouseLeave() {
      this.activeIndex = undefined;
      this.hoverAreaIndex = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin list-active {
  color: var(--colour-brand-puerto-rico);
  text-decoration: none;
  box-shadow: 0 4px 0px -2px var(--colour-brand-puerto-rico);
}

.pin {
  content: '';
  position: absolute;
  margin-left: -15px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent var(--colour-panel-action) transparent;
  top: calc(50% + 1px);
  left: 50%;
  z-index: 999;
}

li {
  color: var(--colour-brand-deluge);
}

.nav-primary {
  display: flex;

  > li {
    color: var(--colour-brand-deluge);
    cursor: pointer;
    display: flex;
    font-weight: var(--font-weight-semibold);
    align-items: center;
    text-decoration: underline;
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
    position: relative;

    &.active {
      @include list-active;
      color: var(--colour-data-puerto-rico);
      box-shadow: none;
      svg {
        path {
          fill: var(--colour-utility-black) !important;
        }
      }
    }

    &.solo-item {
      margin-right: var(--spacing-1);
    }

    &:hover {
      @include list-active;
      svg {
        color: var(--colour-brand-puerto-rico) !important;
        g, path {
          fill: var(--colour-brand-puerto-rico) !important;
        }
      }
    }

    > .svg-container {

      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      transition-duration: 0.5s;

      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      -o-transition-property: -o-transform;
      transition-property: transform;

      svg {
        height: 32px !important;
        width: 32px !important;
      }

      &.chevron-up {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }

      &.chevron-down {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
      }
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

.nav-secondary {
  position: absolute;
  top: 44px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 400px;
  background: var(--colour-panel-action);
  list-style: none;
  z-index: 2;
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-colour) !important;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr;

  &.single {
    display: block;
    .section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  > div:not(.settings) {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);

    &.single {
      grid-area: 1 / 1 / 1 / 3;
    }
  }

  h3 {
    color: var(--colour-utility-black);
    font-size: var(--font-size-4);
    padding: var(--spacing-1) var(--spacing-4);
  }

  .nav-secondary__item {
    font-size: var(--font-size-4);
    list-style-type: none;
    padding: var(--spacing-1) var(--spacing-4);

    &:hover:not(.nav-secondary__item.settings),
    &.active {
      background: var(--colour-panel-g-8);
    }
    &.active a {
      color: var(--colour-data-puerto-rico);
    }
  }

  .nav-secondary__item.settings {
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);

    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    place-content: center;

    a:hover,
    a:active {
      text-decoration: none;
    }
  }

  > li:last-child {
    background-color: var(--colour-utility-white);
    display: flex;
    place-content: center;
    gap: var(--spacing-2);

    &::before {
      background: url(@/shared/assets/icons/search.svg);
      content: '';
      height: 24px;
      width: 24px;
    }
  }
}

.nav-secondary a:active {
  color: inherit;
}
</style>
