export const REPORT_AREA_PEOPLE = 'people';
export const REPORT_AREA_PERFORMANCE = 'performance';
export const REPORT_AREA_PRODUCT = 'products';
export const REPORT_AREA_CUSTOMER = 'customer';
export const REPORT_AREA_ENQUIRY = 'enquiries';
export const REPORT_AREA_AIML = 'aiml';

export const REPORT_AREAS = [
  REPORT_AREA_PEOPLE,
  REPORT_AREA_PERFORMANCE,
  REPORT_AREA_PRODUCT,
  REPORT_AREA_CUSTOMER,
  REPORT_AREA_ENQUIRY,
  REPORT_AREA_AIML,
];
