<template>
  <header class="toolbar">
    <div class="toolbar__content">
      <div class="menu">
        <!-- Logo -->
        <a
          title="sales-i logo"
          href="/"
          class="logo"
          style="flex: 1"
          aria-label="Home"
          @click.prevent="navigateToUrl('/')"
        >
          <template v-if="!loadingLogo">
            <img
              v-if="customIconUrl && breakpoints.smAndDown.includes(mq.current)"
              :src="customIconUrl"
              alt="Custom Icon"
              :height="23"
              :width="23"
            >
            <IconBase
              v-else-if="breakpoints.smAndDown.includes(mq.current)"
              icon-name="sales-i"
              :height="23"
              :width="23"
              icon-color="#EB5F5E"
            />
          
            <img
              v-if="customLogoUrl && breakpoints.mdAndUp.includes(mq.current)"
              :src="customLogoUrl"
              alt="Custom Logo"
              :width="139"
            >
            <IconLogo
              v-else-if="breakpoints.mdAndUp.includes(mq.current)"
              width="139px"
              icon-name="logo"
            />
          </template>
        </a>

        <!-- Navigation -->
        <Navigation
          v-if="breakpoints.lgAndUp.includes(mq.current) && !isInMobileApp"
          v-show="!searchBar && !notifications"
          class="navbar"
          :items="navigation"
        />

        <!-- Actions -->
        <div class="actions">
          <!-- Search bar -->
          <SearchBarResults
            v-if="searchBar"
            :customer-id="customerId"
            :selected-filter="selectedFilter"
            @filter-selected="filterSelected"
            @close="closeSearchBarClicked"
          />

          <!-- Search icon -->
          <CustomButton
            v-if="!searchBar"
            class="search-button"
            icon
            @click.stop="searchBarClicked"
          >
            <IconBase
              icon-name="search"
              :icon-color="iconColor"
              :width="iconWidth"
              :height="iconHeight"
            />
          </CustomButton>

          <!-- Notifications -->
          <Notifications v-show="notifications" />

          <CustomButton
            v-show="!notifications && !searchBar"
            icon
            aria-label="Notifications"
            class="notification-button"
            @click.stop="notificationsClicked"
          >
            <IconBase
              icon-name="bell"
              :icon-color="iconColor"
              :width="iconWidth"
              :height="iconHeight"
            />
            <div
              v-if="notificationCount"
              class="notification-count"
            >
              {{ notificationCount > 99 ? '' : notificationCount }}
            </div>
          </CustomButton>

          <!-- Customer mode -->
          <CustomerMode
            v-if="breakpoints.lgAndUp.includes(mq.current) && !isInMobileApp"
          />

          <CustomButton
            v-if="breakpoints.lgAndUp.includes(mq.current) && !isInMobileApp"
            v-show="!notifications && !searchBar"
            class="settings-button"
            icon
            @click.stop="settingsClicked"
          >
            <div 
              v-if="isSettingsOpen"
              class="settings-container"
            >
              <ul class="links">
                <li>
                  <CustomButton
                    purpose="text"
                    @click.stop="settingsClickHandler"
                  >
                    {{ t('Settings') }}
                  </CustomButton>
                </li>
                <li>
                  <CustomButton
                    class="sign-out"
                    purpose="text"
                    icon-name="exit"
                    icon-align="right"
                    icon-color="--colour-utility-action"
                    @click.stop="handleSignoutClick"
                  >
                    {{ t('Sign out') }}
                  </CustomButton>
                </li>
              </ul>
            </div>
            <IconBase
              icon-name="gear"
              :icon-color="isSettingsRoute ? 'var(--colour-data-puerto-rico)' : iconColor"
              :width="iconWidth"
              :height="iconHeight"
            />
          </CustomButton>

          <CustomButton
            v-if="breakpoints.mdAndDown.includes(mq.current) || isInMobileApp"
            icon
            class="hamburger"
            :width="40"
            :height="40"
            @click.stop="navBarClicked"
          >
            <IconBase
              icon-name="hamburger"
              icon-color="var(--colour-utility-action)"
              :width="24"
              :height="24"
            />
          </CustomButton>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useMq } from 'vue3-mq';
import breakpoints from '@/shared/utils/breakpoints';
import { IconLogo, IconBase, CustomButton } from '@sales-i/dsv3';
import CustomerMode from '@/shared/components/CustomerMode/CustomerMode.vue';
import Navigation from '@/shared/components/Navigation/Navigation.vue';
import Notifications from '@/shared/components/Notifications/Notifications.vue';
import SearchBarResults from '@/shared/components/SearchBarResults/SearchBarResults.vue';
import { LOG_OUT } from '@/shared/store/actionType';
import { navigateToUrl } from 'single-spa';
import { baseUrl as adminBase } from '@/admin/router/urlBits';
import { t, httpRequest } from '@sales-i/utils';

const store = useStore();
const vroute = useRoute();
const mq = useMq();

defineProps({
  isInMobileApp: {
    type: Boolean,
    default: false,
  },
  navigation: {
    type: Array,
    default: () => [],
  },
  notifications: {
    type: Boolean,
  },
  searchBar: {
    type: Boolean,
  },
  selectedFilter: {
    type: String,
    default: '',
  },
  customerId: {
    type: [String, Number],
    default: '',
  },
});
const emit = defineEmits(['navBarClicked', 'notificationsClicked', 'searchBarClicked', 'closeSearchBarClicked', 'filterSelected']);

const iconColor = ref('black');
const iconWidth = ref(32);
const iconHeight = ref(32);
const tabSwitchValue = ref('off');
const isSettingsOpen = ref(false);
const customLogoUrl = ref('');
const customIconUrl = ref('');
const loadingLogo = ref(true);

const mentionsFromStore = computed(() => store.state.mentions.data);
const notificationsFromStore = computed(() => store.state.notifications.data);
const notificationCount = computed(() => notificationsFromStore.value?.length + mentionsFromStore.value?.length);
const isSettingsRoute = computed(() => vroute.path.includes('admin'));

onMounted(() => {
  const hasLocalStoragekey = localStorage.getItem('isCustomerModeEnabled');

  tabSwitchValue.value = hasLocalStoragekey === 'true' ? 'on' : 'off';

  getLogoAPI();
});

const logOutUser = () => store.dispatch(`userDetails/${LOG_OUT}`);

function navBarClicked() {
  emit('navBarClicked', null);
}
function notificationsClicked() {
  emit('notificationsClicked');
}
function searchBarClicked() {
  emit('searchBarClicked');
}
function settingsClicked() {
  isSettingsOpen.value = !isSettingsOpen.value;
}
function settingsClickHandler() {
  isSettingsOpen.value = false;
  navigateToUrl(adminBase);
}
function closeSearchBarClicked() {
  emit('closeSearchBarClicked');
}
function filterSelected(item) {
  emit('filterSelected', item);
}
async function handleSignoutClick() {
  await logOutUser();
  navigateToUrl('/');
  window.location.reload();
}
async function getLogoAPI() {
  try {
    const response = await httpRequest('get', 'tenant-logos', {}, {
      area: 'config',
      headers: { 'Content-Type': 'application/json' },
    });
    customLogoUrl.value = response.logo || '';
    customIconUrl.value = response.icon || '';
  } catch (error) {
    console.error('Error fetching logos:', error);
  } finally {
    loadingLogo.value = false;
  }
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';

.toolbar {
  background-color: var(--colour-utility-white);
  border-color: var(--colour-utility-white);
  box-shadow: var(--shadow-x) var(--shadow-y) var(--shadow-blur) var(--shadow-spread) var(--shadow-colour);
  position: sticky;
  min-height: var(--spacing-8);
  z-index: $headerZIndex;
  margin-top: 0px;
  transform: translateY(0px);
  top: 0;

  .toolbar__content {
    padding: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: var(--spacing-8);
    position: relative;
    z-index: 0;
  }

  .menu {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: map-get($grid-breakpoints, 'xxl');
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);

    @media #{map-get($display-breakpoints, 'xl-and-up')} {
      padding-left: var(--spacing-3);
      padding-right: var(--spacing-3);
    }

    .logo {
      display: flex;

      img {
        height: 50px;
        width: auto;
        max-height: none;
      }
    }
  }

  .logo,
  .actions {
    flex: 1;
  }

  .actions {
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    gap: var(--spacing-1);
    justify-content: flex-end;
  }
}

.notification-button {
  position: relative;
}

.notification-count {
  position: absolute;
  border-radius: 50%;
  background-color: var(--colour-data-mandy);
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-small);
}

.settings-container {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: var(--colour-utility-white);
  padding: var(--spacing-1) var(--spacing-2);

  ul li {
    margin-bottom: var(--spacing-1);
  }

  .sign-out {
    white-space: nowrap;
  }
}
</style>
