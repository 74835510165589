import { computed } from 'vue';
import { useStore } from 'vuex';

export default function usePermissions() {
  const store = useStore();
  const permissions = computed(() => store.state.pbac.permissionsGranted);
  const featureFlags = computed(() => store.state.pbac.featureFlags);
  const hasAccessToPerformanceInsights = computed(() => store.getters['pbac/hasAccessToPerformanceInsights']);
  const hasAccessToProductInsights = computed(() => store.getters['pbac/hasAccessToProductInsights']);
  const hasAccessToCustomerInsights = computed(() => store.getters['pbac/hasAccessToCustomerInsights']);
  const hasAccessToSnapshots = computed(() => store.getters['pbac/hasAccessToSnapshots']);
  const hasAccessToAllInsights = computed(() => store.getters['pbac/hasAccessToAllInsights']);

  const getPermissions = (resource) => permissions.value[resource] || {};
  const hasAccess = (resource, action = 'read') => getPermissions(resource)[action];

  const hasActiveFeature = (feature) => {
    return featureFlags.value.findIndex(o => o === feature) !== -1;
  };

  return {
    permissions,
    hasAccessToPerformanceInsights,
    hasAccessToProductInsights,
    hasAccessToCustomerInsights,
    hasAccessToSnapshots,
    hasAccessToAllInsights,
    hasAccess,
    hasActiveFeature,
    getPermissions,
  };
}