/* eslint-disable no-unused-vars */
import {
  GET_CHILDREN,
  GET_CHILDREN_LOADING,
  GET_CHILDREN_ERROR,
  CLEAR_CHILDREN,
  ROLL_UP,
} from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';

// mutations
export const getChildrenMutations = {
  [GET_CHILDREN]: (state, data) => {
    state.children.data = data;
    state.children.loaded = true;
    state.children.loading = false;
  },
  [GET_CHILDREN_LOADING]: (state) => {
    state.children.loaded = false;
    state.children.loading = true;
  },
  [GET_CHILDREN_ERROR]: (state) => {
    state.children.loaded = true;
    state.children.loading = false;
  },
  [CLEAR_CHILDREN]: (state) => {
    state.children.data = [];
    state.children.loaded = false;
    state.children.loading = false;
  },
  [ROLL_UP]: (state, rollup) => {
    state.children.isRolledUp = rollup;
  }
};

// actions
export const getChildrenActions = {
  [GET_CHILDREN]: async ({ commit, }, id) => {
    try {
      commit(GET_CHILDREN_LOADING);
      const data = await httpRequest('get', `customers/${id}/children`, {}, {version: '2',});
      commit(GET_CHILDREN, data);
    } catch (error) {
      commit(GET_CHILDREN_ERROR);
    }
  },
  [CLEAR_CHILDREN]: ({ commit, }) => {
    commit(CLEAR_CHILDREN);
  },
  [ROLL_UP]: ({ commit }, rollup) => {
    commit(ROLL_UP, rollup);
  }
};
