import { getContactName } from '@/crm/store/utils/mapContactsResponse';

function mapSearchResults(data = [], query = '', type = '') {
  return data.map(item => {
    let header = item.name || item.full_name || 'Untitled';
    let subHeader = '';
    if (['customer', 'prospect'].includes(type)) {
      header = item.name;
      subHeader = item.account_number;
    }
    if (type === 'contact') {
      header = getContactName(item);
      subHeader = item.relationship ? item.relationship.name : '';
    }
    if (type === 'opportunity') {
      header = Array.isArray(item.allocated_users) && item.allocated_users.length 
        ? item.allocated_users[0].allocated_user_id
        : '';
      subHeader = item.entity_name;
    }
    if (type === 'interaction') {
      header = item.user_id;
      subHeader = item.location;
      if (item.relationships) {
        if (Array.isArray(item.relationships.customers) && item.relationships.customers.length && item.relationships.customers[0])
          subHeader = item.relationships.customers[0].name || '';
        else if (Array.isArray(item.relationships.prospects) && item.relationships.prospects.length && item.relationships.prospects[0])
          subHeader = item.relationships.prospects[0].name || '';
      }  
    }
    return {
      ...item,
      query,
      header,
      subHeader,
    };
  });
}

export default mapSearchResults;
