import {
  GET_ALL,
  GET_ALL_LOADING,
  GET_ALL_ERROR,
  CLEAR_ALL,
} from '@/shared/store/actionType';
import { httpRequest } from '@sales-i/utils';

// getters
export const getUsersGetters = {
  getUsersOptions: state => state.data.map(x => ({ text: x.display_name, value: x.id })),
};

// mutations
export const getUsersMutations = {
  [GET_ALL]: (state, data) => {
    state.data = (data || []).map(u => ({...u, display_name: `${u.first_name} ${u.last_name}`}));
    state.loaded = true;
    state.loading = false;
  },
  [GET_ALL_LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [GET_ALL_ERROR]: (state) => {
    state.loaded = false;
    state.loading = false;
  },
  [CLEAR_ALL]: (state) => {
    state.data = [];
    state.loaded = false;
    state.loading = false;
  },
};

// actions
export const getUsersActions = {
  [GET_ALL]: async ({ commit, }) => {
    try {
      commit(GET_ALL_LOADING);
      const data = await httpRequest('get', 'tenants/current/subjects?limit=1000', {}, { area: 'iam', version: '2', }) || [];
      commit(GET_ALL, data);
    } catch (error) {
      console.error(error);
      commit(GET_ALL_ERROR);
    }
  },
  [CLEAR_ALL]: ({ commit, }) => {
    commit(CLEAR_ALL);
  },
};
