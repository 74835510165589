<template>
  <div class="address-field">
    <TextHighlighter
      :text="userAddress"
      :highlight="query"
    />
  </div>
</template>

<script setup>
import { address as addrFuncs } from '@sales-i/utils';
import { computed } from 'vue';
import { TextHighlighter } from '@sales-i/dsv3';

const props = defineProps({
  addresses: {
    type: Object,
    default: () => ({}),
  },
  query: {
    type: String,
    default: '',
  },
});

const userAddress = computed(() => {
  const address = props.addresses;
  return addrFuncs.asString(address);
});
</script>

<style lang="scss" scoped>
.address-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
