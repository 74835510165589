import {
  GET_CONTACTS,
  GET_CONTACTS_LOADING,
  GET_CONTACTS_ERROR,
  CLEAR_CONTACTS
} from '@/crm/store/actionType';
import { httpRequest } from '@sales-i/utils';
import querystring from 'querystring';
import mapContactsResponse from '@/crm/store/utils/mapContactsResponse';


export const getContactsGetters = {
  getDefaultContact: (state) => {
    if (!Array.isArray(state.contacts.data) || !state.contacts.data.length) return {};
    return state.contacts.data.find(x => x.is_default_contact) || {};
  },
  contactOptions: (state) => {
    if (!Array.isArray(state.contacts.data) || !state.contacts.data.length) return [];
    const mergedContacts = state.contacts.data.reduce(
      (prev, curr) => [...prev, ...curr.items.map(x => (
        {
          text: `${x.full_name} ${x.is_default_contact ? ' (default)':''}`,
          value: x.id,
          is_default_contact: x.is_default_contact,
          full_name: x.full_name
        }
      ))],
      []
    );
    return mergedContacts.sort(
      (a, b) => (+b.is_default_contact - a.is_default_contact)
    );
  }
};

// mutations
export const getContactsMutations = {
  [GET_CONTACTS]: (state, data) => {
    state.contacts.data = data;
    state.contacts.loaded = true;
    state.contacts.loading = false;
  },
  [GET_CONTACTS_LOADING]: (state) => {
    state.contacts.loaded = false;
    state.contacts.loading = true;
  },
  [GET_CONTACTS_ERROR]: (state) => {
    state.contacts.loaded = false;
    state.contacts.loading = false;
  },
  [CLEAR_CONTACTS]: (state) => {
    state.contacts.data = [];
    state.contacts.loaded = false;
    state.contacts.loading = false;
  },
};

// actions
export const getContactsActions = {
  [GET_CONTACTS]: async ({ commit, }, options) => {
    try {
      const { id, parameters: { sort, } = {}, } =  options;
      const params = querystring.stringify(options.parameters);
      commit(GET_CONTACTS_LOADING);
      const response = await httpRequest('get', `customers/${id}/contacts?${params}`, {}, { version: '2', });
      const data = mapContactsResponse(response, sort);
      commit(GET_CONTACTS, data);
    } catch (error) {
      console.error(error);
      commit(GET_CONTACTS_ERROR);
    }
  },
  [CLEAR_CONTACTS]: ({ commit, }) => {
    commit(CLEAR_CONTACTS);
  },
};
