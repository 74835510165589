
import { escapeSpecialCharacters } from '@sales-i/utils';
import { replaceSpaceWithNBSP } from '@/shared/utils/replaceSpaceWithNBSP';

// Highlight text
export const highlight = function(text, search){
  if (!text) return '';
  if (!search) return replaceSpaceWithNBSP(text.toString());
  var check = new RegExp(escapeSpecialCharacters(replaceSpaceWithNBSP(search)), 'ig');
  return replaceSpaceWithNBSP(text.toString()).replace(check, function(matchedText){
    return ('<span class="highlight">' + matchedText  + '</span>');
  });
};