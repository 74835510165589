// import associations from '@/admin/store/modules/associations';
// import communities from '@/admin/store/modules/communities';
// import fields from '@/admin/store/modules/fields';
// import fieldTypes from '@/admin/store/modules/fieldTypes';
// import files from '@/admin/store/modules/files';
// import opportunities from '@/admin/store/modules/opportunities';
// import schema from '@/admin/store/modules/schema';
// import settings from '@/admin/store/modules/settings';
// import languages from '@/admin/store/modules/languages';
// import tags from '@/admin/store/modules/tags';
// import aiFeedback from '@/admin/store/modules/aiFeedback';
import intelligence from '@/admin/store/modules/intelligence';
// import pbac from '@/admin/store/modules/pbac';
// import config from '@/admin/store/modules/config';

export default {
  namespaced: true,
  modules: {
    // aiFeedback,
    // associations,
    // communities,
    // fields,
    // fieldTypes,
    // files,
    // opportunities,
    // schema,
    // settings,
    // languages,
    // tags,
    intelligence,
    // pbac,
    // config,
  },
};
