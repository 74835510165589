import customers from '@/crm/store/modules/customers';
import contacts from '@/crm/store/modules/contacts';
import prospects from '@/crm/store/modules/prospects';
import opportunities from '@/crm/store/modules/opportunities';
import interactions from '@/crm/store/modules/interactions';

export default {
  namespaced: true,
  modules: {
    customers,
    contacts,
    prospects,
    opportunities,
    interactions,
  }
};
